import { template as template_068f82041da140318ac3b9ce9de6a032 } from "@ember/template-compiler";
const FKControlMenuContainer = template_068f82041da140318ac3b9ce9de6a032(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
