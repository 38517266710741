import { template as template_c090e764d86947c99a1576e2d14b0bbb } from "@ember/template-compiler";
const FKText = template_c090e764d86947c99a1576e2d14b0bbb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
