import { template as template_ff0c550c6cf74511bc531f7bc02f3c16 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_ff0c550c6cf74511bc531f7bc02f3c16(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
