import { template as template_0b72a7abb3b54e3e8fba8cb2df84f1c8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_0b72a7abb3b54e3e8fba8cb2df84f1c8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
